import React, { Component } from "react";
import { Container } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";


import s1 from "../assets/images/gallery/s1.jpg";
import s2 from "../assets/images/gallery/s2.jpg";
import s3 from "../assets/images/gallery/s3.jpg";
import s4 from "../assets/images/gallery/s4.jpg";
import s5 from "../assets/images/gallery/s5.jpg";
import s6 from "../assets/images/gallery/s6.jpg";
import s7 from "../assets/images/gallery/s7.jpg";
import s8 from "../assets/images/gallery/s8.jpg";

import "../assets/styles/app-slider.css";
export default class AppScreens extends Component {
  render() {
    const state = {
      responsive: {
        0: {
          items: 1,
        },
        450: {
          items: 1,
        },
        600: {
          items: 3,
        },
        768: {
          items: 3,
        },
        1024: {
          items: 5,
        },
      },
    };
    return (
      <div className="section-container" id="app-slider">
        <Container>
          <div className="section-title text-center">
            <h2>App Gallery</h2>
            {/* <p></p> */}
          </div>
          <OwlCarousel
            className={"owl-theme"}
            center={true}
            loop={true}
            margin={20}
            nav={false}
            dots={true}
            autoplay={true}
            items={4}
            responsive={state.responsive}
            autoplayTimeout={5000}
            smartSpeed={4500}
            animateIn="linear"
            animateOut="linear"
          >
            <div className="slider-item">
              <img className="img-fluid" src={s1} alt="" />
            </div>
            <div className="slider-item">
              <img className="img-fluid" src={s2} alt="" />
            </div>
            <div className="slider-item">
              <img className="img-fluid" src={s3} alt="" />
            </div>
            <div className="slider-item">
              <img className="img-fluid" src={s4} alt="" />
            </div>
            <div className="slider-item">
              <img className="img-fluid" src={s5} alt="" />
            </div>
            <div className="slider-item">
              <img className="img-fluid" src={s6} alt="" />
            </div>
            <div className="slider-item">
              <img className="img-fluid" src={s7} alt="" />
            </div>
            <div className="slider-item">
              <img className="img-fluid" src={s8} alt="" />
            </div>
          </OwlCarousel>
        </Container>
      </div>
    );
  }
}

import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../assets/styles/success.css";
import { Link } from "react-router-dom";
import exploreImg from "../assets/images/1.jpg";
import { AiOutlineCloudDownload } from "react-icons/ai";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { BiLogoPlayStore } from "react-icons/bi";
import { GrAppleAppStore } from "react-icons/gr";

const Explore = () => {
  const backgroundStyle = {
    background: `url(${exploreImg}) rgba(0, 0, 0, 0.5)`,
    minHeight: "500px",
    backgroundAttachment: "fixed",
    backgroundPosition: "bottom",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundBlendMode: "multiply",
  };

  return (
    <div style={backgroundStyle}>
      <div className="section-container">
        <div
          style={{
            height: "400px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#ffffff",
          }}
        >
          <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
            <Container>
              <Col
                sm={10}
                md={8}
                lg={8}
                className="text-center offset-ms-1 offset-md-2 offset-lg-2"
              >
                <p className="success-title text-light mb-4">
                  Explore the World with Ease
                </p>
                <div
                  className="text-center"
                  style={{ fontSize: "1.2rem", marginBottom: "2.5rem" }}
                >
                  Effortlessly connect with buyers and sellers of running
                  businesses. Browse listings, chat in real-time, and complete
                  secure transactions with our Business Buying and Selling App.
                  Utilize location-based search to discover businesses nearby.
                  Download now and start buying or selling your running business
                  with ease!{" "}
                </div>
                <Row>
                  <Col>
                    <Link
                      to="https://play.google.com/store/apps/details?id=com.buysellbusines"
                      target="_blank"
                      className="btn-get-started me-2 animate__animated animate__fadeInLeft animate__delay-1s"
                    >
                      {" "}
                      <BiLogoPlayStore /> Play Store
                    </Link>
                  </Col>
                  <Col>
                    <Link
                      to="https://apps.apple.com/us/app/buysellbusines/id6572294761"
                      target="_blank"
                      className="btn-get-started animate__animated animate__fadeInRight animate__delay-1s"
                    >
                      {" "}
                      <GrAppleAppStore /> App Store
                    </Link>
                  </Col>
                </Row>
              </Col>
            </Container>
          </AnimationOnScroll>
        </div>
      </div>
    </div>
  );
};

export default Explore;
